import React from 'react'
import AssetDetail from '../../components/asset-detail'
import Layout from '../../components/layout'

const WatchAsset = ({ location }) => {
    return (
        <Layout>
            <AssetDetail {...location.state} />
        </Layout>
    )
}

export default WatchAsset
