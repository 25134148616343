import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import React, { useEffect, useState } from 'react'
import CustomImage from '../components/custom-image'
import Shelf from '../components/shelf'
import PlayBtn from '../components/play-btn'
import { getAssetData, getHeroImageSize, getIdentifierFromPath, sendGaPlayClickEvent, setGaScreenName } from '../utils'

function addRelatedShelves(categories) {
    return <>{categories && categories.length >= 1 ? getRelatedShelvesForCategories(categories) : null}</>
}

function getRelatedShelvesForCategories(categories) {
    return categories.map(category => {
        const { data = {}, id, type } = category
        const { title } = data

        return <Shelf id={id} type={type} title={title} key={`shelf-${id}`} />
    })
}

function sendGaTabClickEvent(itemTitle, edifloId, tabTitle) {
    const label = `${itemTitle || edifloId || '(not set)'} - ${tabTitle}`

    trackCustomEvent({
        category: 'Navigation',
        action: `Select Tab - ${tabTitle}`,
        label,
    })
}

const AssetDetail = props => {
    const [activeTab, setActiveTab] = useState('details')
    const [assetData, setAssetData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const id = getIdentifierFromPath(props)

    useEffect(() => {
        getAssetData(id)
            .then(data => {
                setAssetData(data)
                setIsLoading(false)
            })
            .catch(err => {
                navigate('/404')
            })
    }, [id])

    useEffect(() => {
        if (assetData.ctitle) {
            setGaScreenName(`Asset Detail - ${assetData.ctitle}`)
        }
    }, [assetData.ctitle])

    if (isLoading) {
        return null
    }

    const {
        categories,
        company,
        ctitle,
        description,
        id: edifloId,
        image,
        language,
        seriesTitle,
        source,
        slug,
    } = assetData
    const showDetailsTab = company || description || language
    const showRelatedTab = categories && categories.length > 0

    if (!showDetailsTab && activeTab !== 'related') {
        setActiveTab('related')
    }

    return (
        <AssetDetailContainer className={!source ? 'missing-src' : ''}>
            <HeroWrapper>
                <InfoWrapper className="info-wrapper">
                    <Info>
                        {seriesTitle ? <sub>{seriesTitle}</sub> : null}
                        <h2>{ctitle}</h2>
                        {source ? (
                            <PlayBtn
                                to={`/player/${slug}`}
                                state={assetData}
                                text="Play"
                                onClick={() => sendGaPlayClickEvent(ctitle, edifloId, 'Play')}
                            />
                        ) : (
                            ''
                        )}
                    </Info>
                </InfoWrapper>
                <ImageWrapper>
                    <Gradient></Gradient>
                    <CustomImage
                        imgSrc={getHeroImageSize(image, 100)}
                        isHero
                        alt={ctitle}
                        imgStyle={{ width: '75vw' }}
                    />
                </ImageWrapper>
            </HeroWrapper>
            <Tabs className="tabs">
                {showDetailsTab ? (
                    <button
                        className={`${activeTab === 'details' ? 'active' : ''} tab`}
                        onClick={_ => {
                            sendGaTabClickEvent(ctitle, edifloId, 'Details')
                            setActiveTab('details')
                        }}
                    >
                        Details
                    </button>
                ) : (
                    ''
                )}
                {showRelatedTab ? (
                    <button
                        className={`${activeTab === 'related' ? 'active' : ''} tab`}
                        onClick={_ => {
                            sendGaTabClickEvent(ctitle, edifloId, 'Related')
                            setActiveTab('related')
                        }}
                    >
                        Related
                    </button>
                ) : (
                    ''
                )}
            </Tabs>
            {showDetailsTab ? (
                <TabContent className={`details ${activeTab === 'details' ? 'active' : ''} content`}>
                    {description ? (
                        <div>
                            <h2>Synopsis</h2>
                            <p>{description}</p>
                        </div>
                    ) : (
                        ''
                    )}
                    {company ? (
                        <div>
                            <h2>Production Company</h2>
                            <p>{company}</p>
                        </div>
                    ) : (
                        ''
                    )}
                    {language ? (
                        <div>
                            <h2>Language</h2>
                            <p>{language}</p>
                        </div>
                    ) : (
                        ''
                    )}
                </TabContent>
            ) : (
                ''
            )}
            {showRelatedTab ? (
                <TabContent className={`related ${activeTab === 'related' ? 'active' : ''} content`}>
                    <div className="related-shelf-wrapper">{addRelatedShelves(categories)}</div>
                </TabContent>
            ) : (
                ''
            )}
        </AssetDetailContainer>
    )
}

const HeroWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    @media screen and (max-width: 672px) {
        position: relative;
        padding-top: 48px;
    }
`

const ImageWrapper = styled.div`
    position: relative;
    width: 75vw;
    height: calc(75vw * 0.5625);
    margin-left: auto;

    @media screen and (max-width: 672px) {
        height: calc(100vw * 0.5625);
        width: 100vw;
        margin: 0;

        img {
            width: 100vw !important;
        }
    }
`

const InfoWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 25vw;
    width: 100%;

    @media screen and (max-width: 672px) {
        position: absolute;
        bottom: 0;
        max-width: unset;
    }
`

const Info = styled.div`
    position: relative;
    margin-right: -400px;
    z-index: 3;
    word-wrap: break-word;
    margin-left: var(--discover-page-margin-desktop);

    a {
        text-decoration: none;
    }

    .play-btn {
        position: initial;
        margin-top: 15px;
    }

    h2 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: white;
        font-size: 36px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 44px;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        margin-top: 4px;
    }

    sub {
        opacity: 0.7;
        color: rgb(255, 255, 255);
        font-size: 12px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1.04px;
        line-height: 16px;
    }

    @media screen and (max-width: 672px) {
        margin-left: var(--discover-page-margin-mobile);
        margin-right: var(--discover-page-margin-mobile);

        .play-btn {
            position: absolute;
            bottom: -52px;
            margin: 0;
            width: 100%;
        }

        h2 {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 20px;
            margin-bottom: 0;
        }
        p {
            display: none;
        }
    }
`

const Gradient = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 641px;
    background: linear-gradient(-90deg, rgba(20, 20, 20, 0) 0%, rgb(20, 20, 20) 100%);

    @media screen and (max-width: 672px) {
        background: linear-gradient(-180deg, rgba(20, 20, 20, 0) 0%, rgb(20, 20, 20) 100%);
        height: 64px;
        width: 100%;
        top: unset;
        bottom: 0;
    }
`

const AssetDetailContainer = styled.div`
    flex-flow: column nowrap;
    background-color: #141414;
    width: 100%;

    @media screen and (max-width: 672px) {
        &.missing-src {
            .info-wrapper {
                margin-bottom: 8px;
            }
            .tabs {
                margin-top: 16px;
            }
        }
    }
`

const TabContent = styled.div`
    display: none;
    flex-flow: row nowrap;
    color: white;
    padding-left: var(--discover-page-margin-desktop);
    max-width: 100vw;
    margin-bottom: 60px;

    > div {
        flex-flow: column nowrap;
        flex-grow: 1;
        flex-basis: 0;

        > h2 {
            color: rgb(255, 255, 255);
            font-size: 20px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: normal;
            letter-spacing: 0.11px;
            line-height: 28px;
            margin-bottom: 12px;
        }
        > p {
            opacity: 0.7;
            color: rgb(255, 255, 255);
            font-size: 16px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: normal;
            letter-spacing: 0px;
            line-height: 20px;
        }
    }

    &.active.content {
        display: flex;
    }
    &.details {
        margin-top: 32px;
    }
    &.related {
        position: relative;
        display: block;
        overflow: hidden;

        .related-shelf-wrapper {
            position: absolute;
            display: block;
            max-width: calc(100vw - 15px);
            padding: 0;
            max-width: 100%;
            left: 9999px;
            top: -9999px;
        }

        &.active {
            .related-shelf-wrapper {
                display: block;
                position: unset;
            }
        }
    }

    @media screen and (max-width: 672px) {
        margin-bottom: 24px;
        flex-flow: column nowrap;
        padding-left: var(--discover-page-margin-mobile);
        padding-right: var(--discover-page-margin-mobile);

        &.details {
            margin-top: 0;
        }

        > div {
            > h2 {
                margin-top: 28px;
                font-size: 17px;
                letter-spacing: 0.1px;
                line-height: 20px;
            }

            > p {
                font-size: 15px;
            }
        }

        &.related {
            padding-right: 0;
        }
    }

    @media screen and (min-width: 672px) {
        > div:nth-of-type(1) {
            padding-right: 120px;
        }
    }
`

const Tabs = styled.div`
    overflow: hidden;
    border: none;
    background-color: #141414;
    color: white;
    padding: 0 var(--discover-page-margin-desktop);
    margin-top: 42px;

    button {
        position: relative;
        opacity: 0.5;
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: bold;
        letter-spacing: 0.14px;
        line-height: 32px;
        background: transparent;
        border: none;
        padding: 0 48px 0 0;
        transition: opacity 0.3s ease-in-out;

        &:hover {
            opacity: 1;
            cursor: pointer;
            transition: opacity 0.3s ease-in-out;
        }

        &:active,
        &:focus {
            outline: none;
            border-style: none;
        }
        &.active.tab {
            opacity: 1;

            &::after {
                content: '';
                width: 22px;
                height: 3px;
                background: rgb(85, 157, 212);
                border-radius: 0px;
                position: absolute;
                bottom: 0px;
                left: 0;
            }
        }
    }

    @media screen and (max-width: 672px) {
        margin-top: 90px;
        padding: 0 var(--discover-page-margin-mobile);

        button {
            font-size: 17px;
            letter-spacing: 0.1px;
            padding-right: 36px;

            &.active.tab {
                &::after {
                    bottom: 1px;
                }
            }
        }
    }
`

export default AssetDetail
